import { React } from 'react';

import {
    useSigninCheck
} from 'reactfire';

import Stories from '../Stories';

import { signOut, getAuth, signInWithRedirect, GithubAuthProvider } from "firebase/auth";


export default function Login() {

    const { signInStatus, data: signInCheckResult } = useSigninCheck();

    if (signInStatus === "loading") {
        return <span>loading...</span>;
    }
    if (signInCheckResult?.signedIn === false) {
        return (
            <>
                <button onClick={login}>Login</button>
            </>);
    }
    console.log("uid: " + signInCheckResult?.user.uid)
    if (signInCheckResult?.signedIn === true) {
        return <>
            <h1>Hello, {signInCheckResult.user.displayName}! </h1>

            <button onClick={logout} >Logout</button>
            <Stories showUnapproved={true} />
        </>;
    }

    function logout() {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    async function login(e) {
        e.preventDefault();

        const auth = getAuth();
        let provider = new GithubAuthProvider(auth);
        // let user;

        signInWithRedirect(auth, provider)
            .then((result) => {
                // This gives you a GitHub Access Token. You can use it to access the GitHub API.
                // const credential = GithubAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;

                // The signed-in user info.
                // user = result.user;
                // // alert(JSON.stringify(user))
                // ...
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.email;
                // // The AuthCredential type that was used.
                // const credential = GithubAuthProvider.credentialFromError(error);
                // ...
            });
    }

    return (
        <>

        </>);
}