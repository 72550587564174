import { React } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, Link
} from "react-router-dom";

import { getFirestore } from 'firebase/firestore';
import {
    FirestoreProvider,
    AuthProvider,
    useFirebaseApp,
    AnalyticsProvider,
} from 'reactfire';
import { getAnalytics } from 'firebase/analytics';
import { AddStory } from "./AddStory";
import Stories from "./Stories";
import StoryComments from "./StoryComments"
import Logo from "./components/Logo"
import AnalyticsComponent from "./components/AnalyticsComponent";


import { getAuth } from "firebase/auth";
import Login from "./components/Login";

import { Adsense } from "./components/Adsense";
import { About } from "./pages/About";
import { Privacy } from "./pages/Privacy";

export default function App() {
    let app = useFirebaseApp();

    const firestoreInstance = getFirestore(app);
    const auth = getAuth(app);

    return (
        <AuthProvider sdk={auth}>
            <AnalyticsProvider sdk={getAnalytics(app)}>
                <FirestoreProvider sdk={firestoreInstance}>
                    <Router>
                        <div className="container">
                            <br />
                            <Adsense />
                            <div className="row">
                                <div className="col-lg-12">
                                    <Logo />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="wrapper wrapper-content animated fadeInRight">
                                        <br />



                                        <Switch>
                                            <Route path="/admin">
                                                <Login />
                                            </Route>
                                            <Route path="/about" component={About} />
                                            <Route path="/privacy" component={Privacy} />
                                            <Route path="/story/:slug/:action/:secret" component={StoryComments} />
                                            <Route path="/story/:slug" component={StoryComments} />
                                            <Route path="/">
                                                <div className="ibox-content m-b-sm">
                                                    <div className="p-xs">


                                                        {/* <i className="fa-solid fa-charging-station"></i> */}
                                                        <span className=""> Submit your true stories or experiences from driving, owning an Electric Vehicle (EV). We seek the truth about EVs and to make EV driving, charging, owning or renting a better experience in the future. </span>
                                                        <i className="fa-solid fa-charging-station"></i>

                                                    </div>
                                                </div>
                                                {<AddStory />}
                                                <Stories />
                                            </Route>

                                        </Switch>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="">© 2021 | <Link to="about">About</Link> | <Link to="privacy">Privacy</Link>   </span>
                                </div>
                            </div>
                            <br />
                        </div>
                        <AnalyticsComponent />
                    </Router>
                </FirestoreProvider>
            </AnalyticsProvider>
        </AuthProvider>
    );
}
