import { React } from 'react';

export function Privacy() {
    return <><div id=":2ev" class="a3s aiL ">
        <div dir="ltr">
            <div class="gmail_default" >
                <h1>Data Protection Policy (GDPR) Compliance</h1>
                <div><p>All organisations are required to comply with the General Data Protection Regulations.
                    Information relating to this and our processing of data are set out below.</p>
                </div>
                <h1>Website Cookies</h1>
                <div>
                    <div>
                        <p>
                            Our website may use cookies to collect information. This includes information about browsing and purchasing behaviour by people who access our website. This includes information about pages viewed, products purchased and interaction with our website.
                        </p>
                    </div>
                </div>
                <h1 >Google Analytics</h1>
                <div >
                    <div >
                        <p >When someone visits our website, we use a third-party service, Google Analytics, to collect standard internet log information and details of visitor behaviour patterns. We do this to find out things such as the number of visitors to various parts of the site. This information may only be processed in a way which does not identify an individual to allow us to make sure our website is serving our customers needs. We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website.</p></div></div><h1 >Marketing</h1><div ><div ><p >We may ask for consent to send you occasional marketing messages, using email or text messages. You will have the right to opt out of any message you receive with a clear unsubscribe link. Alternatively, you can email us at&nbsp;<a href="mailto:team@ev-stories.com" target="_blank">team@ev-stories.com</a>&nbsp;to request being removed from such marketing messages. You may continue to receive messages in relation to any transactions with us such as booking confirmations, reminders or receipts.</p>
                        </div>
                </div>
                <h1 >Access to your Personal Information</h1>
                <div ><div>
                    <p >You have a number of rights in respect of data which we hold relating to you, including viewing, amending, or deleting any such personal data that we hold. More information on Data Protection and your rights can be obtained from the Information Commissioner’s Office –&nbsp;<a href="https://ico.org.uk/your-data-matters/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://ico.org.uk/your-data-matters/&amp;source=gmail&amp;ust=1636236495530000&amp;usg=AFQjCNGrpFMwYnqGRiNPTfLBU34ZYoXXqA">https://ico.org.uk/your-data-matters/</a>.</p><p >To make any request pursuant to any such rights, you should email&nbsp;<a href="mailto:team@ev-stories.com" target="_blank">team@ev-stories.com</a>&nbsp;&nbsp;.</p></div></div><h1 >Data Protection Requests</h1><div ><div ><p >You may contact us about our processing of data via email at <a href="mailto:team@ev-stories.com" target="_blank">team@ev-stories.com</a></p><p >More information on Data Protection and your rights can be obtained from the Information Commissioner’s Office –&nbsp;<a href="https://ico.org.uk/your-data-matters/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://ico.org.uk/your-data-matters/&amp;source=gmail&amp;ust=1636236495530000&amp;usg=AFQjCNGrpFMwYnqGRiNPTfLBU34ZYoXXqA">https://ico.org.uk/your-data-matters/</a></p></div></div></div></div><div class="yj6qo"></div><div class="adL">
        </div></div></>
}