import { useState, React } from 'react';
import { v4 as uuidv4 } from 'uuid';
import statuses from './constants';
import { collection, addDoc, Timestamp, updateDoc } from 'firebase/firestore';
import {

    useFirestore,

} from 'reactfire';

export function AddStory() {
    const [newStoryText, setNewStoryText] = useState();
    const [submittedStoryText, setSubmittedStoryText] = useState();
    // const [newSubscriberEmailText, setNewSubscriberEmailText] = useState();
    const [submitted, setSubmitted] = useState(false);

    const db = useFirestore();

    async function handleClick(e) {
        e.preventDefault();
        console.log("clicked");
        console.log(newStoryText);
        let approval_secret = uuidv4()

        let storyRef = await addDoc(collection(db, "stories"), {
            body: newStoryText,
            date_added: Timestamp.now(),
            status: statuses.SUBMITTED,
            approval_secret,
            likes:[]
        });

        await updateDoc(storyRef, {
            slug: storyRef.id
        });

        setSubmittedStoryText(newStoryText);

        let viewLink = `https://ev-stories.com/story/${storyRef.id}`;
        let approvalLink = `https://ev-stories.com/story/${storyRef.id}/approve/${approval_secret}`;

        console.log("emailing");
        await addDoc(collection(db, "mail"), {
            to: ["team@ev-stories.com"],
            message: {
                subject: "New story for approval",
                html: `<p>${newStoryText}</p><br/> <a href='${viewLink}'>View</a ><br/><br/> <a href='${approvalLink}'>Approve</a >`
            },
        });

        setNewStoryText("");
        setSubmitted(true);

        console.log("email entry saved");
    }

    const handleChange = (event) => {
        setNewStoryText(event.target.value);
        setSubmitted(false)
    };

    // async function handleEmailClick(e) {
    //     e.preventDefault();
    //     console.log("email subscribe button clicked");
    //     console.log(newStoryText);
    //     let docRef = await addDoc(collection(db, "stories"), {
    //         body: newStoryText,
    //         date_added: Timestamp.now(),
    //         date_approved: null,
    //         approver: null
    //     });

    //     setSubmitted(true);
    // }

    // const handleEmailChange = (event) => {
    //     setNewSubscriberEmailText(event.target.value);
    // };

    const handleSubmitAnotherClick = (event) => {
        setSubmitted(false)
    }
    return (
        <div>
            {submitted === false ?
                <div>
                    <div className="d-flex justify-content-center">
                        <textarea className="new-story-text" style={{ resize: "none" }} placeholder="There was this one time..." value={newStoryText} onChange={handleChange} />
                        &nbsp;&nbsp;&nbsp;

                    </div>
                    <button style={{ marginTop: "5px" }} className="btn btn-sm btn-success" disabled={!newStoryText} onClick={handleClick}>Submit story anonymously</button>
                </div> : ""
            }
            {
                submitted === true ?
                    <div>
                        <div className="row card-body" style={{ marginBottom: "5px" }}>
                            <div className="col-md-12 ">
                                <span className="bolt">
                                    <i className="fa fa-bolt"></i>
                                </span>&nbsp;<span className="badge text-dark">{new Date().toString()}</span>
                                {/* <a href="/{story.data().title}" className="forum-item-title">{story.data().body}</a> */}
                                <br /><br /><div className="forum-sub-title">{submittedStoryText}</div>
                            </div>
                            <br />

                        </div>
                        <div className="badge bg-success">Thanks for your story! We're looking forward to reading it and publishing it.</div>
                        {/* <br /><br /><div>For notifications leave an email address (totally optional)</div><input name="email" onChange={handleEmailChange} ></input>
                        &nbsp;<button className="btn btn-sm btn-warning" onClick={handleEmailClick}>Get notified</button>
                        &nbsp;or&nbsp; */}
                        <br /><br />
                        <button className="btn btn-sm btn-secondary" onClick={handleSubmitAnotherClick}>Submit another</button>
                    </div> : ""
            }
        </div >
    )
}