import { React } from 'react';
import { render } from 'react-dom';

import App from "./App"

import {
    FirebaseAppProvider
} from 'reactfire';

import './index.css';

/**
 * Add your own Firebase config to watch the burrito status
 * update in real time!
 *
 * Once you add your config, go to the Firestore tab of the
 * Firebase console and create a collection called
 * "tryreactfire", and create a document inside that
 * collection called "burrito" with key "yummy"
 * and value "good" or "bad"
 */
const firebaseConfig = {
    apiKey: "AIzaSyB1KjzSeIhGPLxbe8e72FilmhFDpyEYC-c",
    authDomain: "ev-stories.firebaseapp.com",
    databaseURL: "https://ev-stories-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ev-stories",
    storageBucket: "ev-stories.appspot.com",
    messagingSenderId: "672698386124",
    appId: "1:672698386124:web:f14fa77fd79c98e0bd6469",
    measurementId: "G-44F97P8X37"
};
render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <App />

    </FirebaseAppProvider >,
    document.getElementById('root')
);
