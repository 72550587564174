import { React } from 'react';
import { Link } from 'react-router-dom'
export function About() {
    return <>
        <br />

        <img src="/electric-car-2728131_640.png" width="80%" alt="Generic electric car plugged in, nice and green."></img><br /> <br />
        <p>We are trying to help seek and share experiences and truth about electric cars and make EV driving, charging, owning or renting a better experience in the future. </p>

        <p>A few of us bought EVs this year, taking the plunge into the unknown. We found ourselves sharing stories with each other on whatsapp.
            As a result, we found there was commonality, and we learnt from each other.
            Here we are seeking to broaden the reach of this shared knowledge and experiences.
        </p>

        <p>Whether it is a Tesla, Mercedes EQA/EQC, Audi QTron, Porsche Taycan, Renault Zoe, Kia Niro or oven a polestar, we'd love to hear your experience.</p>

        <p>Add your experience or comment to the stories and help everyone on the journey to a better EV world.
        </p>

        <p>And don't worry if you've done something stupid.... Getting locked out of my tesla 1m away from a Tesla charger, that was me 😳. Read <Link to="/">this and other stories</Link> and leave a comment for everyone to see, any time you like.</p>


    </>
}