import { React, useState } from 'react';

import { updateDoc, Timestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import {
    useSigninCheck,
} from 'reactfire';


import TextareaAutosize from 'react-textarea-autosize';

import statuses from './constants';

function Comments({ comments, story }) {
    const [newComment, setNewComment] = useState();
    const { signInStatus, data: signInCheckResult } = useSigninCheck();

    if (signInStatus === "loading") {
        return <span>loading...</span>;
    }

    function isAdmin() {
        return signInCheckResult?.user?.uid === "9nt17OTUjdcgRcXtNIixIhDqEHk1" || signInCheckResult?.user?.uid === "Hxck7bF6XzXBmynS6ypqBNetW9d2" || signInCheckResult?.user?.uid === "9jddji6SxhaFBTYMMyqmAFFf1a82";
    }

    const addComment = async (e) => {

        e.preventDefault();

        // console.log(newStoryText);

        await updateDoc(story.ref, {
            comments: arrayUnion({
                text: newComment,
                date_added: Timestamp.now(),
                status: statuses.SUBMITTED
            })
        });

        setNewComment("");

        // setSubmittedStoryText(newStoryText);
        // setNewStoryText("");
        // setSubmitted(true);
    }
    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    }

    async function trash(e, comment) {
        await updateDoc(story.ref, {
            comments: arrayRemove(comment)
        });
    }

    return (
        <>
            <div>Replies</div>
            <TextareaAutosize className="new-comment-text" onChange={handleCommentChange} value={newComment} placeholder='Polite and respectful comment...' /> <button className='btn btn-secondary' disabled={!newComment} onClick={addComment}>Reply</button>
            <div>
                {
                    comments ?
                        comments.map((comment, index) => {
                            return (
                                <>
                                    {
                                        <div key={index}>
                                            {/* <button id={-1} onClick={replyToggleClicked} className='btn'>Cancel {index}</button> */}
                                            <div className='comment'>
                                                <span className="bolt">
                                                    <i className="fa fa-bolt"></i>
                                                </span>&nbsp;<span className="badge text-dark">{new Date(comment.date_added?.seconds * 1000).toString()}</span>
                                                <span className='card card-body'>{comment.text}<br />
                                                    {isAdmin() ?
                                                        <button onClick={e =>
                                                            window.confirm("Are you sure? There is no 3rd chance...") && trash(e, comment)} className='btn btn-light pull-right'>🗑️</button>
                                                        : <></>}</span> <br />

                                                {/*Comments comments={comment.comments} />*/}</div>
                                        </div>
                                    }

                                </>
                            )
                        }) : <></>
                }
            </div>
        </>
    )
}


export default Comments
