import { useState, React, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useFirestore, useSigninCheck } from 'reactfire';
import TextareaAutosize from 'react-textarea-autosize';
import { updateDoc, doc, deleteDoc } from 'firebase/firestore'
import CopyToClipboard from 'react-copy-to-clipboard';
import statuses from './constants';
import FingerprintJS from '@fingerprintjs/fingerprintjs'


function Story({ story, renderLinks }) {
    const [slug, setSlug] = useState();

    useEffect(() => {
        setSlug(generate_slug(story.data().body));
    }, [story]);

    function generate_slug(story_body) {
        return story_body.replace(/[.,/#!$%^&*;:{}=\-_'`~()]/g, "").split(' ').slice(0, 5).join('-').toLowerCase().replace(/\s{2,}/g, " ").replace(" ", "") + "-" + Date.now().toString().substring(9)
    }

    let db = useFirestore();
    const docRef = doc(db, "stories", story.id);

    const { signInStatus, data: signInCheckResult } = useSigninCheck();

    if (signInStatus === "loading") {
        return <span>loading...</span>;
    }
 
    // only show approved to non-signed-in
    if (story.data().status !== statuses.APPROVED && signInCheckResult?.signedIn !== true) {
        return <></>;
    }

    // don't show rejected to anyone
    if (story.data().status === statuses.REJECTED) {
        return <></>;
    }

    function isAdmin() {
        return signInCheckResult.user.uid === "9nt17OTUjdcgRcXtNIixIhDqEHk1" || signInCheckResult.user.uid === "Hxck7bF6XzXBmynS6ypqBNetW9d2" || signInCheckResult.user.uid === "9jddji6SxhaFBTYMMyqmAFFf1a82";
    }

    async function approve(e) {
        if (signInCheckResult?.signedIn === true) {
            if (isAdmin()) {
                await updateDoc(docRef, { status: statuses.APPROVED, date_approved: new Date(), approver: signInCheckResult.user.displayName, slug: slug.replace(/[.,/#!$%^&*;:{}=_'`~()]/g, "").replace(/\s{2,}/g, " ").replace(" ", "").toLowerCase() })
            }
        } else { alert("only admins can approve stories") }
    }

    async function spam(e) {
        if (signInCheckResult?.signedIn === true) {
            if (signInCheckResult.user.uid === "9nt17OTUjdcgRcXtNIixIhDqEHk1" || signInCheckResult.user.uid === "Hxck7bF6XzXBmynS6ypqBNetW9d2" || signInCheckResult.user.uid === "9jddji6SxhaFBTYMMyqmAFFf1a82") {
                await updateDoc(docRef, { status: statuses.REJECTED, date_rejected: new Date(), approver: signInCheckResult.user.displayName, spam: true })
            }
        } else { alert("only admins can mark stories as spam") }
    }
    async function trash(e) {
        if (signInCheckResult?.signedIn === true) {
            if (signInCheckResult.user.uid === "9nt17OTUjdcgRcXtNIixIhDqEHk1" || signInCheckResult.user.uid === "Hxck7bF6XzXBmynS6ypqBNetW9d2" || signInCheckResult.user.uid === "9jddji6SxhaFBTYMMyqmAFFf1a82") {
                await deleteDoc(docRef)
            }
        } else { alert("only admins can delete stories") }
    }

    async function handleSlugChange(e) {
        setSlug(e.target.value)
    }

   


    async function like(e) {
        // Initialize an agent at application startup.
        const fingerprintPromise = FingerprintJS.load();

        const fingerPrint = await fingerprintPromise
        const uniqueUser = await fingerPrint.get()
        const visitorId = uniqueUser.visitorId
        console.log(visitorId)

        if (!story.data().likes.includes(visitorId)) {
            await updateDoc(docRef, { likes: [...story.data().likes, visitorId] })
        }
        else {
            await updateDoc(docRef, { likes: story.data().likes.filter(uid => uid !== visitorId) })
        }

    }

    function getRandomSkinToneThumbsUp() {
        const emojis = ["👍","👍🏻","👍🏼","👍🏽","👍🏾","👍🏿"];
        const emoji = emojis[Math.floor(Math.random() * emojis.length)];
        return emoji;
    }

    return (
        <div key={story.id} >
            <div className="forum-item active">
                <div className={story.data().status === statuses.APPROVED ? "row card-body" : "row card-body unapproved"} style={{ marginBottom: "5px" }}>
                    <div className="col-md-12 ">
                        <span className="bolt">
                            <i className="fa fa-bolt"></i>
                        </span>&nbsp;<span className="badge text-dark">{new Date(story.data()?.date_added?.seconds * 1000).toString()}</span>
                        {/* <a href="/{story.data().title}" className="forum-item-title">{story.data().body}</a> */}
                        <br /><br />
                        {
                            signInCheckResult?.signedIn === true ?
                                <TextareaAutosize className="new-body-text forum-sub-title" value={story.data().body} /> :
                                <div className=" forum-sub-title">{story.data().body}</div>
                        }
                        {/* {story.id} */}
                    </div>
                    <br />
                    <div className="col-md-12">

                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;{
                            renderLinks && story.data().slug && story.data().status === statuses.APPROVED ?
                                <>
                                <button className="btn btn-sm btn-secondary bg-light text-dark" onClick={like}>{story.data().likes.length} Like {getRandomSkinToneThumbsUp()}</button>&nbsp;
                                <Link to={"/story/" + story.data().slug} className="btn btn-sm btn-secondary bg-light text-dark">{story.data().comments ? story.data().comments.length : 0} Comment{story.data().comments?.length === 1 ? "" : "s"} 💬</Link>
                                </> : <></>
                        }
                        &nbsp;
                        <span className="dropdown">
                            <button className="btn btn-sm btn-secondary bg-light text-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Share story
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <CopyToClipboard text={"https://ev-stories.com/story/" + story.data().slug}
                                        onCopy={() => {
                                            const copyDiv = document.querySelector('.copyAlert:not(.animate)')
                                            if(copyDiv) {
                                                copyDiv.classList.add('animate');
                                                copyDiv.addEventListener('animationend', () => copyDiv.classList.remove('animate') );
                                            }
                                        }}>
                                        <span className='dropdown-item'>Copy story link</span>
                                    </CopyToClipboard>
                                </li>

                            </ul>
                        </span>
                        &nbsp;<span class="copyAlert">Link copied!</span>
                    </div>
                    {
                        story.data().status === statuses.SUBMITTED ?
                            <div className='well'>
                                <button onClick={approve} className='btn btn-success'>Approve</button>&nbsp;
                                Slug <input placeholder='' value={slug} onChange={handleSlugChange}></input>&nbsp;
                                <button onClick={spam} className='btn btn-danger'>Mark as Spam</button>&nbsp;
                                <button onClick={e =>
                                    window.confirm("Are you sure you wish to delete this story? There is no 3rd chance...") && trash(e)} className='btn btn-light pull-right'>🗑️</button>
                                {/* <button className='btn btn-danger'>Reject</button> */}
                            </div> : ""
                    }

                </div>

            </div>
        </div >
    );
}


export default Story
