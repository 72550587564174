import { React } from 'react';

import { useParams, Link } from 'react-router-dom';

import { query, where, collection } from 'firebase/firestore';
import {
    useFirestoreCollection,
    useFirestore,
} from 'reactfire';

import Comments from "./Comments";
import Story from './Story';

function StoryComments() {
    let params = useParams();
    let storySlug = params.slug;

    const storiesRef = collection(useFirestore(), 'stories');
    const storiesQuery = query(storiesRef, where("slug", "==", storySlug));

    const { status, data } = useFirestoreCollection(storiesQuery);

    if (status === 'loading') {
        return <p>Fetching story...</p>;
    }

    if (params.action === "approve") {
        if (data?.docs[0]?.data().approval_secret === params.secret) {
            console.log("Approve - secret is correct, approving.... MANUAL approve for now");
            // await updateDoc(data?.docs[0], { date_approved: Timestamp.now(), status: statuses.APPROVED, approver: "email link" })
        }
    }

    return (

        data?.docs?.length > 0 ?
            data?.docs?.reverse().map((story, index) => {
                return (
                    <div key={index}>
                        <Link to="/" className='btn btn-sm btn-secondary bg-light text-dark' >⬅ Back</Link >
                        <Story story={story} />
                        <div>{story.data().comments?.length === undefined ? "0" : story.data().comments?.length} comment(s)</div>
                        <Comments comments={story.data().comments?.reverse()} story={story} />
                        < br />
                    </div>
                )
            }) : <div><h1>Uh-oh! Battery depleted.</h1>Story not found, not to worry, plenty more to read...  <br /> < Link to="/" >See all stories</Link ></div>
    )
}


export default StoryComments
