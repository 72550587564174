import { React } from 'react';

import { query, orderBy, collection, updateDoc } from 'firebase/firestore';
import {
    useFirestoreCollection,
    useFirestore,
    
} from 'reactfire';

// import { signOut, getAuth } from "firebase/auth";
import statuses from './constants';
import Story from './Story';

export default function Stories({ showUnapproved = false }) {
    const db = useFirestore();
    const storiesRef = collection(db, 'stories');
    let storiesQuery = query(storiesRef, orderBy("date_added", "desc"));

    const { status, data } = useFirestoreCollection(storiesQuery);

    if (status === 'loading') {
        return <p>Fetching stories...</p>;
    }

    let stories = data?.docs
    console.log(data)


    // Uncomment to reset approval status of all documents
    // stories.forEach(async function (doc) {
    //     await updateDoc(doc.ref, { status: "submitted" })
    // });
    stories.forEach(async (doc) => {
    //    await updateDoc(doc.ref, { likes: [] })
    })

    // function logout() {
    //     const auth = getAuth();
    //     signOut(auth).then(() => {
    //         // Sign-out successful.
    //     }).catch((error) => {
    //         // An error happened.
    //     });
    // }

    return (
        <>
            <div>There are <b>{stories.filter((d) => d.data().status === statuses.APPROVED).length}</b> great stories so far.</div>
            {
                stories?.map((story, index) => {
                    return (
                        <Story key={story.id} story={story} renderLinks={true} />
                    )
                })
            }
        </>
    )
}
