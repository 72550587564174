import { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
    useAnalytics
} from 'reactfire';
import { logEvent } from 'firebase/analytics';

const AnalyticsComponent = () => {
    const history = useHistory();
    const analytics = useAnalytics();
    let href = window.location.href;

    useEffect(() => {
        logEvent(analytics, 'page_view', { page_location: window.location.href })
        // this might log twice for the first page load and then transition to next page
        history.listen(() => {
            logEvent(analytics, 'page_view', { page_location: window.location.href })
        });
    }, [href, analytics, history]);
    return (<div></div>);
};

export default AnalyticsComponent